<template>
  <div class="find-flower-sister-list">
    <AddressPosition />
    <div class="list-container">
      <!-- <div class="header">
        <span class="text">探花姐姐</span>
      </div> -->
      <div class="list">
        <el-row :gutter="52">
          <el-col v-for="item in loading ? 6 : list" :key="loading ? item : item.id" :span="8">
            <el-skeleton :loading="loading" animated style="width: 373px;">
              <ShadowCard
                :cover-src="item.cover"
                cover-width="373px"
                cover-height="281px"
                clickable
                @click.native="toFindFlowerSisterDetailPage(item)"
              >
                <template #content>
                  <div class="card-title">{{ item.title }}</div>
                </template>
              </ShadowCard>
              <template #template>
                <el-skeleton-item variant="image" style="width: 373px; height: 281px; margin-bottom: 40px;" />
                <div style="padding: 14px;">
                  <el-skeleton-item variant="p" style="width: 50%" />
                  <div style="display: flex; align-items: center; justify-items: space-between;">
                    <el-skeleton-item variant="text" style="margin-right: 16px;" />
                    <el-skeleton-item variant="text" style="width: 30%;" />
                  </div>
                </div>
              </template>
            </el-skeleton>
          </el-col>
        </el-row>
      </div>
      <!-- 分页 -->
      <el-pagination
        style="text-align: center;margin-bottom: 60px;"
        background
        layout="prev, pager, next, total, jumper"
        prev-text="上一页"
        next-text="下一页"
        :page.sync="listQuery.pageNum"
        :page-size.sync="listQuery.pageSize"
        @current-change="getListData"
        :total="total"
      />
    </div>
  </div>
</template>

<script>
import { getFindFlowerSisterList } from '@/api'
import ShadowCard from '@/components/ShadowCard.vue'
import AddressPosition from '@/components/AddressPosition'
import { scrollTo } from '@/utils/scrollTo'
// import { MINI_PROGRAM_PAGE_LIST } from '@/utils/constant'
import imgSrc from "@/utils/bigDataIMG.js"
const bigDataIMG = imgSrc.data()

export default {
  name: 'CloudLiveFindFlowerSisterList',

  components: { AddressPosition, ShadowCard },

  data() {
    return {
      // 列表
      list: [],
      total: 0,
      loading: true,
      // 列表请求参数
      listQuery: {
        pageNum: 1,
        pageSize: 9
      },
      bigDataIMG
    }
  },

  created() {
    this.getListData()
    scrollTo(0, 800)
  },

  methods: {
    /* 获取探花姐姐列表数据 */
    getListData(pageNum) {
      this.loading = true
      // 构造请求参数
      const { listQuery } = this
      const query = {
        ...listQuery,
        pageNum: pageNum || listQuery.pageNum
      }
      return getFindFlowerSisterList(query).then(res => {
        const { content: list, total } = res.data
        this.total = total
        this.list = list
      }).catch(error => {
        this.list = []
        this.total = 0
        console.log('getListData', error)
      }).finally(() => {
        this.loading = false
      })
    },

    /* 跳转到探花姐姐视频详情页 */
    toFindFlowerSisterDetailPage(row) {
      const { id } = row
      id && window.open(`/cloud/findFlowerSister/detail?id=${id}`)
    }
  }
}
</script>

<style lang="less" scoped>
.find-flower-sister-list {
  width: 1280px;
  margin: 0 auto;
  .list-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .header {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc(332px - 32px * 2);
      height: 70px;
      margin: 70px 0;
      font-size: 28px;
      font-weight: 600;
      color: #fff;
      // background: linear-gradient(90deg, #c9d7ff 0%, #7f95d1 100%);
      background: linear-gradient(90deg, #9aee96 0%, #349477 100%);
      .icon {
        margin-right: 5px;
        object-fit: cover;
      }
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -31px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 35px 32px 35px 0;
        // border-color: transparent #c9d7ff transparent transparent;
        border-color: transparent #9aee96 transparent transparent;
      }
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: -31px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 35px 0 35px 32px;
        // border-color: transparent transparent transparent #7f95d1;
        border-color: transparent transparent transparent #349477;
      }
    }
    .list {
      .el-row {
        width: 1280px;
      }
      .el-col {
        margin-bottom: 40px;
      }
      .card-title {
        height: 97px;
        line-height: 97px;
        text-align: center;
        font-size: 18px;
        color: #333;
      }
    }
  }
}
</style>