<template>
  <div class="m-page find-flower-sister-detail">
    <div ref="topContainer" class="top-container">
      <!-- 视频区域 -->
      <div class="video-container">
        <video :src="detail.video" controls class="video" />
      </div>
      <!-- tab栏区域 -->
      <el-tabs v-model="currentTab" class="tab-container">
        <el-tab-pane
          v-for="{ label, value } in tabList"
          :key="value"
          :label="label"
          :name="value"
        />
      </el-tabs>
    </div>
    <div :style="bottomContainerStyleObj" class="bottom-container">
      <div v-if="currentTab === 'introduction'" class="introduction-container">
        <div v-if="detail.title" class="title-wrap">
          {{ detail.title || '' }}
        </div>
        <div v-if="detail.beginTime" class="time-wrap">
          {{ detail.beginTime || '' }} 开始
        </div>
        <!-- <div class="btn-wrap">
          <div class="left">
            <div class="views">
              <i class="el-icon-view" />
              <text>{{ detail.viewCountTxt }}</text>
            </div>
            <div class="favorites">
              <div @click="onClickCollect">
                <text>{{ detail.collectCountTxt }}</text>
              </div>
            </div>
          </div>
          <div class="right">
            <van-button
              plain
              icon="share-o"
              size="small"
              color="#999"
              class="share"
              open-type="share"
            >
              分享
            </van-button>
          </div>
        </div> -->
        <div v-if="detail.description" class="description-wrap">
          <div class="title">视频详情</div>
          <div class="description">
            {{ detail.description }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getFindFlowerSisterDetail } from '@/api'

export default {
  name: 'CloudFindFlowerSisterDetail',

  data() {
    return {
      // 详情数据
      detail: {},
      // 当前tab
      currentTab: 'introduction',
      // tab栏选项列表数据
      tabList: [
        { label: '介绍', value: 'introduction' },
        // { label: '互动', value: 'interaction' },
        // { label: '排行榜', value: 'ranking' },
        // { label: '视频回顾', value: 'review' }
      ],
      topContainerHeight: 0
    }
  },

  created() {
    const { id } = this.$route.query
    id && this.getDetailData(id)
  },
  
  mounted() {
    this.topContainerHeight = this.$refs['topContainer'].clientHeight
  },

  computed: {
    bottomContainerStyleObj() {
      const ratio = 37.5 / 54
      const { topContainerHeight } = this
      return { 'height': `calc(100vh - ${topContainerHeight}px - 15px * 2 * ${ratio})` }
    }
  },

  methods: {
    /* 发送请求, 获取详情数据 */
    getDetailData(id) {
      getFindFlowerSisterDetail({ id }).then(res => {
        const detail = res.data
        const { title = '' } = detail
        title && (document.title = `${title} - ${document.title}`)
        this.detail = detail
      }).catch(error => {
        console.log('getDetailData', error)
      })
    },
  }
}
</script>

<style lang="less" scoped>
@ratio: 37.5 / 54;
.find-flower-sister-detail {
  min-height: 100vh;
  max-height: 100vh;
  position: relative;
  overflow: hidden;
  .top-container {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    .video-container {
      width: 100%;
      height: calc(450px * @ratio);
      min-height: calc(450px * @ratio);
      line-height: calc(450px * @ratio);
      text-align: center;
      background-color: #000;
    }
    .video-container .video {
      width: 100%;
      height: 100%;
    }
    .tab-container {
      padding: calc(15px * @ratio) calc(30px * @ratio) calc(15px * @ratio);
      zoom: calc(1 / @ratio);
      box-sizing: border-box;
      ::v-deep .el-tabs__item {
        &:hover {
          color: #555ca3;
        }
      }
      ::v-deep .el-tabs__item.is-active {
        color: #555ca3;
      }
      ::v-deep .el-tabs__active-bar {
        height: calc(6px * @ratio);
        border-radius: calc(5px * @ratio);
        background-color: #555ca3;
      }
      ::v-deep .el-tabs__nav-wrap::after {
        width: 0;
      }
    }
  }
  .bottom-container {
    width: 100%;
    overflow-y: scroll;
    .introduction-container {
      padding: 0 calc(22px * @ratio) calc(28px * @ratio);
      .title-wrap {
        margin: calc(28px * @ratio) calc(11px * @ratio);
        line-height: calc(56px * @ratio);
        font-size: calc(40px * @ratio);
        font-weight: 600;
        color: #333;
      }
      .time-wrap {
        margin: calc(28px * @ratio) calc(11px * @ratio);
        line-height: calc(11px * @ratio);
        font-size: calc(24px * @ratio);
        color: #666;
      }
      .description-wrap {
        margin: calc(28px * @ratio) calc(11px * @ratio);
        .title {
          margin: calc(14px * @ratio) 0;
          line-height: calc(50px * @ratio);
          font-size: calc(34px * @ratio);
          font-weight: 600;
          color: #333;
        }
        .description {
          font-size: calc(32px * @ratio);
          line-height: calc(45px * @ratio);
          color: #666;
        }
      }
    }
  }
}
</style>