<template>
  <div class="m-page">
    <img src="https://img.miaocang.cc/pcImg/cloud/exhibition_schedule.jpg" alt="" />
  </div>
</template>

<script>
export default {
  name: 'CloudExhibitionSchedule',

  created() {
    document.title = '展会日程'
  }
}
</script>

<style lang="less" scoped>
.m-page {
  min-height: 100vh;
  text-align: center;
  background-color: #7c97cc;
  img {
    width: 100vw;
    min-width: 320px;
    max-width: 640px;
  }
}
</style>